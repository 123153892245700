<ng-container *ngIf="suggestedProfession$ | async as suggestedProfession">
  <div class="result-card" (click)="onCardClick($event)">
    <div class="result-card-inner-container">
      <div class="result-card-top-container">
        <h2 class="profession-title">{{ suggestedProfession.title }}</h2>
        <div class="tag-container">
          <!-- Knelpuntberoep tag -->
          <vdab-ai-tag *ngIf="shortageProfession$ | async" appearance="success" [light]="true">{{
            tcs.getText("knelpuntberoep")
            }}</vdab-ai-tag>
          <!-- Weinig vacatures tag -->
          <vdab-ai-tag *ngIf="fewVacanciesProfession$ | async" appearance="danger" [light]="true">{{
            tcs.getText("few_vacancies")
            }}</vdab-ai-tag>
        </div>
      </div>
      <div class="graph-container">
        <vdab-ai-half-donut-chart
          *ngIf="suggestedProfessionHasConfidenceScore(suggestedProfession)"
          (click)="openExplanationModal(suggestedProfession)"
          [percentage]="suggestedProfession.confidenceScore"
          [color]="calculateColor(suggestedProfession.confidenceScore)"
          [text]="tcs.getText('match')"
          [enableInfoIcon]="true"
        >
        </vdab-ai-half-donut-chart>
      </div>
      <div class="description-container">
        <p>
          {{ suggestedProfession.description }}
        </p>
      </div>

      <div class="actions-container">
        <a class="link-text u-mr1" [href]="professionLinkUrl$ | async">
          {{ tcs.getText("read_everything_about_profession") }}
        </a>
        <a class="link-text u-mr1" [href]="vacancyLinkUrl$ | async">
          {{ tcs.getText("vacancies") }}
        </a>
        <a class="link-text u-mr1" [href]="courseLinkUrl$ | async">
          {{ tcs.getText("courses") }}
        </a>
      </div>
    </div>
  </div>
</ng-container>
